import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin';
import { ResourceName } from '../../config/config';

export const ServiceCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="ownerUserId" label="Owner ID" />
                <TextInput source="title" label="Title" />
                <TextInput source="details" label="Details" />
                <SelectInput
                    source="currency"
                    choices={[
                        { id: 'EUR', name: '€' },
                        { id: 'LKR', name: 'Rs.' },
                        { id: 'USD', name: '$' },
                    ]}
                />
                <NumberInput source="durationMinutes" label="Duration in minutes" />
                <NumberInput
                    source="price"
                    format={(price: number) => price / 100}
                    parse={(price: number) => price * 100}
                    label="price"
                />
                <ReferenceInput label="Category" source="categoryKey" reference={ResourceName.categories} perPage={100}>
                    <SelectInput optionText="displayName" optionValue="key" />
                </ReferenceInput>
                <TextInput source="expertUserId" label="Expert User ID" />
            </SimpleForm>
        </Create>
    );
};
