import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class ServiceDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.services;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { serviceId, ownerId, expertId, status, search } = params.filter;
        let { field, order } = params.sort;
        if (field === 'id') {
            field = 'serviceId';
        } else if (field === 'ownerId') {
            field = 'ownerName';
        } else if (field === 'categoryKey') {
            field = 'categoryDisplayName';
        }
        const query = serviceId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  ids: serviceId,
                  ownerId: ownerId || undefined,
                  expertId: expertId || undefined,
                  status: status || undefined,
                  q: search || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  ownerId: ownerId || undefined,
                  expertId: expertId || undefined,
                  status: status || undefined,
                  q: search || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              };
        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.services.map((service: any) => ({
                                ...service,
                                id: service.serviceId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.serviceId } });
                })
                .catch(reject);
        });
    }

    getMany(params: DataProviderParams): Promise<any> {
        let idsQuery = '';
        for (const id of params.ids) {
            idsQuery = idsQuery.concat(idsQuery === '' ? id : `,${id}`);
        }

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?ids=${idsQuery}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.services.map((service: any) => ({
                                ...service,
                                id: service.serviceId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    create(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient('', {
                method: 'POST',
                body: JSON.stringify(params.data),
            })
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.serviceId } });
                })
                .catch(reject);
        });
    }

    update(params: DataProviderParams): Promise<any> {
        if (params.data.image) {
            const formData = new FormData();

            for (const key in params.data.image) {
                formData.append('image', params.data.image[key]);
            }

            new Promise((resolve, reject) => {
                this.getHttpClient(`${params.id}/image`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response: any) => {
                        resolve({ data: {} });
                    })
                    .catch(reject);
            });
        }
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    featured: params.data.featured,
                    featuredRank: params.data.featuredRank,
                    status: params.data.status,
                    details: params.data.details,
                    categoryKey: params?.data?.category?.key,
                }),
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }
}

export default new ServiceDataProvider();
