import React from 'react';
import { List, Datagrid, TextField, EmailField, Filter, SearchInput } from 'react-admin';
import { PageResults } from '../../pageResults/PageResults';

export const ChatList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        hasCreate={false}
        perPage={25}
        pagination={<PageResults />}
        filters={
            <Filter>
                <SearchInput placeholder="User ID" source="userId" alwaysOn />
                <SearchInput placeholder="E-mail" source="email" alwaysOn />
                <SearchInput placeholder="Name" source="name" alwaysOn />
            </Filter>
        }
        sort={{ field: 'joinedDate', order: 'DESC' }}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" label="User ID" />
            <TextField source="ownerProfile.name" label="Name" />
            <EmailField  style={{ color: '#6482d6' }} source="email" />
        </Datagrid>
    </List>
);
